.loading {
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #2e2e2e 74%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
    animation: pulse 2s infinite ease-in-out;
    transition: 1s;
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
  }

  img {
    width: 300px;
    animation: pulse 2s infinite ease-in-out;
    transition: 2s;
  }

  @keyframes pulse {
    0% {
      opacity: 0.8;
      transform: scale(0.98);
    }
    50% {
      opacity: 0.9;
      transform: scale(1);
    }
    100% {
      opacity: 1;
      transform: scale(0.98);
    }
  }
}
