.bp3-button {
  background-color: unset !important;
  background-image: unset !important;
  box-shadow: unset !important;
}

.btn {
  border-radius: 20px;
  span {
    font-size: 14px !important;
    font-weight: bold;
  }
}

.btn-1 {
  background-color: $primary-yellow-color !important;

  &:hover {
    background-color: #ca9842 !important;
  }
  &:focus {
    outline-color: #9b7e4b;
    outline-style: solid !important;
    outline-offset: 0px !important;
  }
}

.btn-2 {
  background-color: #000 !important;
  span {
    color: #fff;
  }
  &:focus {
    outline-color: #ddd;
  }
}

.btn-3 {
  background-color: transparent !important;
  border: 1px solid #fff;
  span {
    color: #fff;
  }
  &:focus {
    outline-color: #ddd;
    outline-style: solid !important;
    outline-offset: 0px !important;
  }
}
