body {
  font-family: $primary-font !important;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  min-height: calc(
    100% + env(safe-area-inset-top) + env(safe-area-inset-bottom)
  );
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

a {
  color: #438aff;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    color: lighten(#438aff, 10%);
    text-decoration: none;
  }
}

strong {
  font-weight: 600 !important;
}

svg {
  pointer-events: none;
}

mark {
  word-break: break-word;
}

input:not([type="checkbox"]):not(.invisible),
textarea,
select {
  -webkit-appearance: none; // workaround for box-shadows in Safari

  &:focus {
    outline: none !important;
    border: none !important;
    box-shadow: inset 0 0 0 1px rgba(67, 138, 255, 0.8) !important;
  }
}

input,
textarea,
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.no-margin {
  margin: 0 !important;
}

.space-bottom {
  margin-bottom: 16px;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.no-padding {
  padding: 0 !important;
}

.bp3-button.pb-button {
  &.pb-button-blue {
    border-radius: 4px;
    background-color: #2b75ff;
    background-image: none;
    box-shadow: none;
    color: white;
    padding: 8px 18px;
    span {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.16px;
      line-height: 24px;
    }
  }
  &.pb-button-large {
    padding: 12px 16px;
  }
  & .bp3-button-text {
    line-height: 1.4;
    font-weight: 600;
  }
}
