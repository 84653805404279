/** Widths and Heights **/
@for $i from 1 to 101 {
  .w-#{$i} {
    width: #{$i}#{"%"};
  }
  .h-#{$i} {
    height: #{$i}#{"%"};
  }
}

@for $i from 0 to 101 {
  .vw-#{$i} {
    width: #{$i}#{"vw"};
  }
  .vh-#{$i} {
    height: #{$i}#{"vh"};
  }
}

// Margins and Paddings Classes (including mobile)

@for $i from 0 to 101 {
  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }

  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }

  .m-#{$i} {
    margin: #{$i}px !important;
  }

  .my-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }

  .mx-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }

  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }

  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }

  .p-#{$i} {
    padding: #{$i}px !important;
  }

  .py-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }

  .px-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }

  @media screen and (max-width: 959px) {
    .m-mbl-t-#{$i} {
      margin-top: #{$i}px !important;
    }

    .m-mbl-r-#{$i} {
      margin-right: #{$i}px !important;
    }

    .m-mbl-b-#{$i} {
      margin-bottom: #{$i}px !important;
    }

    .m-mbl-l-#{$i} {
      margin-left: #{$i}px !important;
    }

    .m-mbl-#{$i} {
      margin: #{$i}px !important;
    }

    .p-mbl-t-#{$i} {
      padding-top: #{$i}px !important;
    }

    .p-mbl-r-#{$i} {
      padding-right: #{$i}px !important;
    }

    .p-mbl-b-#{$i} {
      padding-bottom: #{$i}px !important;
    }

    .p-mbl-l-#{$i} {
      padding-left: #{$i}px !important;
    }

    .p-mbl-#{$i} {
      padding: #{$i}px !important;
    }
  }

  @media print {
    .m-pr-t-#{$i} {
      margin-top: #{$i}px !important;
    }

    .m-pr-r-#{$i} {
      margin-right: #{$i}px !important;
    }

    .m-pr-b-#{$i} {
      margin-bottom: #{$i}px !important;
    }

    .m-pr-l-#{$i} {
      margin-left: #{$i}px !important;
    }

    .m-pr-#{$i} {
      margin: #{$i}px !important;
    }

    .p-pr-t-#{$i} {
      padding-top: #{$i}px !important;
    }

    .p-pr-r-#{$i} {
      padding-right: #{$i}px !important;
    }

    .p-pr-b-#{$i} {
      padding-bottom: #{$i}px !important;
    }

    .p-pr-l-#{$i} {
      padding-left: #{$i}px !important;
    }

    .p-pr-#{$i} {
      padding: #{$i}px !important;
    }
  }
}

.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.m-auto {
  margin: auto;
}
.my-auto {
  margin: auto 0;
}
.mx-auto {
  margin: 0 auto;
}

@media screen and (max-width: 959px) {
  .mt-mlb-auto {
    margin-top: auto;
  }
  .mb-mlb-auto {
    margin-bottom: auto;
  }
  .ml-mlb-auto {
    margin-left: auto;
  }
  .mr-mlb-auto {
    margin-right: auto;
  }
  .m-mlb-auto {
    margin: auto;
  }
  .my-mlb-auto {
    margin: auto 0;
  }
  .mx-mlb-auto {
    margin: 0 auto;
  }
}

.mobile-only {
  @media screen and (min-width: 960px) {
    display: none !important;
  }
}

.desktop-only {
  @media screen and (max-width: 959px) {
    display: none !important;
  }
}
